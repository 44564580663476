import { render, staticRenderFns } from "./Delivery.vue?vue&type=template&id=2f9691c0&"
import script from "./Delivery.js?vue&type=script&lang=js&"
export * from "./Delivery.js?vue&type=script&lang=js&"
import style0 from "./Delivery.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QCircularProgress from 'quasar/src/components/circular-progress/QCircularProgress.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QImg from 'quasar/src/components/img/QImg.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QIcon,QBtn,QForm,QInput,QSelect,QDate,QBtnToggle,QCircularProgress,QRadio,QImg});
