import EstadosBrasileiros from 'src/core/mixin/EstadosBrasileiros'
import Carregando from 'src/components/CarregandoGeral/Carregando.vue'
import AgendamentoModel from 'src/model/usuario/AgendamentoModel'
import LogradouroCepModel from 'src/model/cep/LogradouroCepModel.js'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import vTopFilters from 'src/core/filters/vTopFilters'
import Modal from 'src/components/Modal/Modal.vue'
import { LocalStorage, clone } from 'quasar'

export default {
  name: 'Delivery',
  components: { Modal, Carregando },
  mixins: [EstadosBrasileiros, vTopFilters, NotificacaoMixin],
  props: ['delivery', 'locais'],
  data () {
    return {
      modelLogradouroCep: new LogradouroCepModel(),
      modelAgendamento: new AgendamentoModel(),

      logado: LocalStorage.getItem('logado'),
      usuarioSelecionado: LocalStorage.getItem('logado'),
      anuncioId: null,

      dataAgendamento: '',
      horarioAgendamento: '',

      salvando: false,
      carregando: true,

      horarioOptions: [
        { label: '09:30', value: '09:30:00' },
        { label: '10:30', value: '10:30:00' },
        { label: '11:30', value: '11:30:00' },
        { label: '13:00', value: '13:00:00' },
        { label: '14:30', value: '14:30:00' },
        { label: '15:00', value: '15:00:00' },
        { label: '16:00', value: '16:00:00' },
        { label: '17:00', value: '17:00:00' },
        { label: '18:00', value: '18:00:00' }
      ],

      // Modal
      modalTipo: '',
      value: false,
      dados: {
        valorTarifa: 0,
        km: 100,
        valorPorKm: 1.2,
        labelCancelar: 'Fechar'
      },
      possuiCompromisso: false
    }
  },
  computed: {
    locale () {
      const _meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
      const _dias = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado']
      return {
        months: _meses,
        monthsShort: _meses.map(mes => mes.substr(0, 3)),
        days: _dias,
        daysShort: _dias.map((mes) => mes.substr(0, 3))
      }
    }
  },
  mounted () {
    this.anuncioId = this.$route.params.id

    if (this.logado && this.logado.id !== null && this.logado.roles && this.logado.roles.find(e => e.name === 'Vendedor')) {
      if (LocalStorage.has('usuarioSelecionado')) {
        this.usuarioSelecionado = LocalStorage.getItem('usuarioSelecionado')
      }
    }

    if (this.logado) {
      if (this.anuncioId) {
        if (this.delivery) {
          this.responseGetAgendamento(this.delivery)
        } else {
          this.getAgendamento()
        }
      } else {
        this.notificacao('aviso', 'Nenhum Anúncio Selecionado!', 3000)
        this.$router.go(-1)
      }
    } else {
      this.notificacao('aviso', 'Você não está logado!', 3000)
      this.$router.go(-1)
    }
  },
  methods: {
    toggleModal (tipo) {
      this.value = !this.value
      this.modalTipo = tipo
    },

    callbackAcao () {
      if (this.modalTipo === 'reagendar') {
        this.possuiCompromisso = false
        this.value = false
      }

      if (this.modalTipo === 'desmarcar') {
        this.deletarAgendamento()
        this.value = false
      }

      if (this.modalTipo === 'tarifa') {
        this.value = false
      }
    },

    validarCep (value) {
      const cep = value
      if (cep !== null && cep.length === 9) {
        this.modelLogradouroCep = new LogradouroCepModel()
        this.modelLogradouroCep.recurso = this.modelLogradouroCep.recurso.replace('{cep}', cep)
        this.modelLogradouroCep
          .getListagem()
          .then(res => {
            if (res && res.dados) {
              var objCep = res.dados

              this.modelAgendamento.form.cep = objCep.cep
              this.modelAgendamento.form.rua = objCep.logradouro
              this.modelAgendamento.form.bairro = objCep.bairro
              this.modelAgendamento.form.cidade = objCep.localidade
              this.modelAgendamento.form.estado = objCep.uf
              this.modelAgendamento.form.complemento = ''
              this.modelAgendamento.form.numero = ''
              this.modelAgendamento.form.ponto_referencia = ''

              this.searchLocationPostalCode(objCep.cep)
            } else this.notificacao('erro', 'CEP Inválido!')
          })
          .catch(error => {
            this.notificacao('erro', error.msg)
          })
      }
    },

    async searchLocationPostalCode (cep) {
      // Requisão de coordenardas atraves do CEP
      // const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${cep}&key=AIzaSyACvzwSrshOnkIEy6Fac8l4UPFIfWshrMQ`
      const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${cep}&key=AIzaSyALwOITk84I708iFcs17kliW1b-uF7Fhmg`
      const request = new Request(url, { method: 'POST', mode: 'cors', cache: 'default' })
      const res = await fetch(request)
      const r = await res.json()
      /* */

      if (r.status === 'OK' && r && r.results && r.results.length > 0) {
        // Response Coordenadas endereco
        this.modelAgendamento.form.latitude = r.results[0].geometry.location.lat.toFixed(4)
        this.modelAgendamento.form.longitude = r.results[0].geometry.location.lng.toFixed(4)

        // Calcular distancia de cada cidade
        this.locais.map((item, i) => {
          const distancia = this.getDistanceFromLatLonInKm(
            { lat: this.modelAgendamento.form.latitude, lng: this.modelAgendamento.form.longitude },
            { lat: item.latitude, lng: item.longitude }
          )
          this.locais[i].distancia = distancia
        })

        // validar se esta longe para cobrar adicionar
        const locaisOrder = this.locais.sort(this.sortDistancia)
        const distanciaMenor = locaisOrder.find(e => e.distancia < (this.dados.km * 1000))

        if (!distanciaMenor) {
          const todosLonge = locaisOrder.filter(e => e.distancia >= (this.dados.km * 1000))
          const primeiroLonge = todosLonge && todosLonge.length > 0 ? todosLonge[0] : null
          const custo = primeiroLonge && primeiroLonge.distancia ? ((primeiroLonge.distancia / 1000) * this.dados.valorPorKm) : 0

          this.modelAgendamento.form = Object.assign({}, this.modelAgendamento.form, {
            custo: custo.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
          })

          this.modelAgendamento.form.custo = custo.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
          this.dados.valorTarifa = this.modelAgendamento.form.custo
          this.toggleModal('tarifa')
        }
        /* */
      }
    },

    sortDistancia (a, b) {
      return a.distancia < b.distancia ? -1 : a.distancia > b.distancia ? 1 : 0
    },

    getDistanceFromLatLonInKm (position1, position2) {
      var deg2rad = function (deg) {
          return deg * (Math.PI / 180)
        },
        R = 6371,
        dLat = deg2rad(position2.lat - position1.lat),
        dLng = deg2rad(position2.lng - position1.lng),
        a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(position1.lat)) * Math.cos(deg2rad(position1.lat)) * Math.sin(dLng / 2) * Math.sin(dLng / 2),
        c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
      return ((R * c * 1000).toFixed())
    },

    getAgendamento () {
      const search = { tipo: 'Delivery', usuario_id: this.usuarioSelecionado.id, anuncio_id: this.anuncioId }
      const params = { buscaGrid: search, searchJoin: 'and' }

      this.modelAgendamento.getListagem({ params }).then((res) => {
        const form = res.dados && res.dados.length > 0 ? res.dados[0] : null
        this.responseGetAgendamento(form)
      }).catch(error => {
        this.notificacao('erro', error.msg)
        console.error(error)
        this.carregando = false
      })
    },

    responseGetAgendamento (form) {
      this.carregando = false
      this.salvando = false

      if (form) {
        if (form.data_atendimento) {
          this.possuiCompromisso = true
          const data = form.data_atendimento.split(' ')

          this.dataAgendamento = data[0].replace(/-/g, '/')
          this.horarioAgendamento = data[1]
        }

        this.modelAgendamento.form = form
      }
    },

    validarDataAgendamento () {
      let dtPassado = false

      let dataAgendamento = this.dataAgendamento ? this.dataAgendamento.replace(/\//g, '-') : null
      const hoje = this.$options.filters.hojeEng()
      const horaAgora = this.$options.filters.horaHoje()

      // Validar se data do agendamento esta no passado
      if (dataAgendamento < hoje) {
        this.notificacao('aviso', 'Este horário não está mais disponívelo!')
        dataAgendamento = hoje
        dtPassado = true
      }
      /* */

      // Validar se hora do agendamento esta no passado
      if (dataAgendamento === hoje) {
        const horarioAgendamento = this.$options.filters.hora(`${dataAgendamento} ${this.horarioAgendamento}`)
        const horaAgoraSonumero = this.$options.filters.soNumeros(horaAgora)
        const horarioAgendamentoSonumero = this.$options.filters.soNumeros(horarioAgendamento)

        if (horarioAgendamentoSonumero < horaAgoraSonumero) {
          console.error('opaaa hora do passado!')

          // exibir notificacao de erro
          if (!dtPassado) {
            this.notificacao('aviso', 'Este horário não está mais disponível!')
            dtPassado = true
          }
          /* */

          // Colocar um proximo horario do futuro ou presente
          const encontradoHoraDisponivel = this.horarioOptions.find(e => {
            const value = this.$options.filters.hora(`${dataAgendamento} ${e.value}`)
            const valueSonumero = this.$options.filters.soNumeros(value)
            return valueSonumero >= horaAgoraSonumero
          })

          this.horarioAgendamento = encontradoHoraDisponivel ? encontradoHoraDisponivel.value : null
          /* */
        }
      }
      /* */

      // eslint-disable-next-line
      this.dataAgendamento = dataAgendamento ? dataAgendamento.replace(/\-/g, '/') : null

      return dtPassado
    },

    submitAgendamento () {
      if (this.validarDataAgendamento()) {
        return false
      }

      this.salvando = true

      const aviso = this.notificacao('enviando')
      const send = clone(this.modelAgendamento.form)
      send.anuncio_id = this.anuncioId
      send.usuario_id = this.usuarioSelecionado.id
      send.tipo = 'Delivery'

      if (this.$route.params.dealId) {
        send.deal_id = this.$route.params.dealId.toString()
      } else if (LocalStorage.has('dealId')) {
        send.deal_id = (LocalStorage.getItem('dealId')).toString()
      }

      if (this.dataAgendamento && this.horarioAgendamento) {
        send.data_atendimento = `${this.dataAgendamento.replace(/\//g, '-')} ${this.horarioAgendamento}`
      } else {
        this.notificacao('aviso', 'Data de Agendamento Obrigatório')
        return false
      }

      if (send.custo) {
        send.custo = (send.custo.toString().replace(/\D/g, '') / 100).toFixed(2)
      }

      this.modelAgendamento.salvar(send).then((res) => {
        aviso()
        this.notificacao('salvo', res.msg, 3000)
        this.$emit('atualizarAgendamemto')
        this.getAgendamento()
      }).catch(error => {
        aviso()
        this.notificacao('erro', error.msg)
        console.error(error)
        this.salvando = false
      })
    },
    deletarAgendamento () {
      if (this.modelAgendamento.form.id) {
        this.carregando = true

        const notify = this.notificacao('enviando', 'Desmarcado...')
        this.modelAgendamento.deletar(this.modelAgendamento.form.id).then(() => {
          this.notificacao('salvo', 'Desmarcado com sucesso', 8000)
          notify()

          this.modelAgendamento = new AgendamentoModel()
          this.possuiCompromisso = false
          this.carregando = false
        }).catch((error) => {
          this.notificacao('erro', error.msg, 8000)
          this.carregando = false
        })
      } else {
        this.notificacao('aviso', 'Não existe compromisso marcado!', 8000)
      }
    }
  }
}
